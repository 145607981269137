.cardproduct {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  border-radius: 0.25rem;
}

.progress .progress-bar:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.progress.progress-bar-sm {
  height: 0.5rem;
}
.progress.progress-bar-xs {
  height: 0.3rem;
}
.progress.progress-bar-rounded {
  border-radius: 30px;
}

.progress-bar-animated-alt.progress-bar,
.progress-bar-animated-alt .progress-bar {
  position: relative;
}
.progress-bar-animated-alt.progress-bar::after,
.progress-bar-animated-alt .progress-bar::after {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  animation: progress-active 2s ease infinite;
}

@keyframes progress-active {
  0% {
    opacity: 0.4;
    width: 0;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}
.icon-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
}

.widget-chart {
  text-align: center;
  padding: 1rem;
  position: relative;
}
.widget-chart .widget-chart-content {
  position: relative;
  z-index: 5;
}
.widget-chart .widget-chart-content-lg {
  padding: 2rem 0 1rem 2rem;
}
.widget-chart .widget-chart-content-lg .widget-numbers {
  margin-bottom: 0;
}
.widget-chart .widget-chart-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.25;
  z-index: 6;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  overflow: hidden;
}
.widget-chart .widget-numbers {
  font-weight: bold;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  margin: 1rem auto;
}
.widget-chart .widget-numbers + .widget-description,
.widget-chart .widget-numbers + .widget-subheading {
  margin-top: -0.5rem;
}
.widget-chart .widget-subheading {
  margin: -0.5rem 0 0;
  display: block;
  opacity: 0.6;
}
.widget-chart .widget-subheading:first-child {
  margin-top: 0;
}
.widget-chart .widget-subheading + .widget-numbers {
  margin-top: 0.5rem;
}
.widget-chart .widget-description {
  margin: 1rem 0 0;
}
.widget-chart .widget-chart-actions {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 12;
}
.widget-chart .widget-chart-actions .btn-link {
  font-size: 1.1rem;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0.6;
}
.widget-chart .chart-wrapper-relative {
  position: relative;
  opacity: 1;
  margin-top: 1rem;
}

.widget-chart-actions {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 12;
}
.widget-chart-actions .btn-link {
  font-size: 1.1rem;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0.6;
}

.widget-chart:hover .widget-chart-actions .btn-link,
.widget-content:hover .widget-chart-actions .btn-link {
  opacity: 1;
}

.widget-content .widget-content-wrapper {
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
}

.icon-wrapper {
  width: 54px;
  height: 54px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.icon-wrapper[class*="border-"] {
  border-width: 1px;
  border-style: solid;
}
.icon-wrapper .icon-wrapper-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: 0.2;
}
.icon-wrapper .icon-wrapper-bg.bg-light {
  opacity: 0.08;
}
.icon-wrapper i {
  margin: 0 auto;
  font-size: 1.7rem;
  position: relative;
  z-index: 5;
}
.icon-wrapper i:before {
  margin-top: -3px;
}

.card-btm-border {
  border-bottom: transparent solid 4px;
}

.widget-numbers-sm {
  font-size: 1.5rem;
}

.widget-numbers-sm {
  font-size: 1.5rem;
}

.bg-love-kiss {
  background-image: linear-gradient(
    to top,
    #ff0844 0%,
    #ffb199 100%
  ) !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.lnr-cog:before {
  content: "\e810";
}

.text-muted {
  color: #6c757d !important;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 1px;
  overflow: hidden;
  background: #e9ecef;
}

:root {
  --warning: #dd5600;
}
