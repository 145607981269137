.product_tile {
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: 1px solid #ddd;
  transition: all 500ms ease-out;
  &:hover {
    box-shadow: 0 50px 50px -5px rgba(0, 0, 0, 0.2);
  }
}
